import React from "react";
import styled from "styled-components";
import { FaUserMd, FaCalendarCheck, FaClinicMedical } from "react-icons/fa";
import { motion } from "framer-motion";
import medicalbg from '../Images/medicalbg.jpg';
import { useNavigate } from 'react-router-dom';

const MedicalAppFeatures = () => {
  const navigate = useNavigate();
  const sections = [
    {
      title: "Patient Portal",
      icon: <FaUserMd />, 
      features: [
        "User registration with profile management",
        "Secure login and password reset",
        "Book and manage appointments",
        "View medical history and prescriptions",
        "Receive automated appointment reminders",
        "Search for doctors by specialty",
        "Rate and review doctors",
        "Access telemedicine consultations",
        "Download medical reports",
        "Live chat support",
      ],
    },
    {
      title: "Doctor Dashboard",
      icon: <FaCalendarCheck />, 
      features: [
        "Doctor login and profile management",
        "Manage appointment schedules",
        "View patient medical history",
        "Approve or reject appointment requests",
        "Send prescriptions and notes to patients",
        "Conduct telemedicine consultations",
        "Secure messaging with patients",
        "Track patient follow-ups",
        "Generate digital prescriptions",
      ],
    },
    {
      title: "Clinic Admin Panel",
      icon: <FaClinicMedical />, 
      features: [
        "Manage doctors and staff profiles",
        "Monitor appointments and patient flow",
        "Generate reports on clinic performance",
        "Send announcements to patients and doctors",
        "Handle billing and payment processing",
        "Manage telemedicine integration",
        "Set up automated appointment reminders",
        "View and respond to patient feedback",
      ],
    },
  ];

  return (
    <PageWrapper>
      <IntroSection>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <IntroText>
            <h1>Medical Appointment Booking App Features</h1>
            <p>
              Simplify healthcare management with our feature-rich appointment booking app.
              Designed for patients, doctors, and clinic administrators.
            </p>
          </IntroText>
        </motion.div>
      </IntroSection>
      <FeaturesSection>
        {sections.map((section, index) => (
          <FeatureCard
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <CardHeader>
              <Icon>{section.icon}</Icon>
              <Title>{section.title}</Title>
            </CardHeader>
            <FeaturesList>
              {section.features.map((feature, idx) => (
                <FeatureItem key={idx}>• {feature}</FeatureItem>
              ))}
            </FeaturesList>
          </FeatureCard>
        ))}
      </FeaturesSection>
      <ButtonWrap>
        <Button onClick={() => navigate('/contactus')}>Get Started</Button>
      </ButtonWrap>
    </PageWrapper>
  );
};

export default MedicalAppFeatures;

// Styled Components
const PageWrapper = styled.div`
  width: 100%;
  padding: 5px;
  color: #333;
  padding-top: 50px;
  background-image: url(${medicalbg});
  background-position: center;
  background-size: cover;
`;

const IntroSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

const IntroText = styled.div`
  max-width: 800px;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
  }

  @media(max-width:768px){
    h1{
    font-size:1.8rem;
    }
  }
`;

const FeaturesSection = styled.section`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0px;
  flex-wrap: wrap;
`;

const FeatureCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 45%;

  @media(max-width: 768px) {
    width: 100%;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: rgba(0, 0, 255, 0.5);
  margin-right: 10px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const Button = styled.button`
  padding: 20px;
  background-color: white;
  color: rgba(0, 0, 255, 0.5);
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
