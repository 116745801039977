import React from 'react';
import styled from 'styled-components';
import conclusionBg from '../Images/9864.jpg'
import { useNavigate } from 'react-router-dom';

// Main Wrapper for the Component
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  width: 100%;
  min-height: 80vh;
  background-image: url(${conclusionBg});
  background-size: cover;
  background-position: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 0;
  }
`;

// Content Container
const Content = styled.div`
  z-index: 1;
  text-align: center;
  max-width: 800px;
  color: white;

  h1 {
    font-size: 2.8em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #f3f4f6;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #e2e8f0;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.2em;
    }

    p {
      font-size: 1em;
    }
  }
`;

// Call to Action Button
const Button = styled.button`
  padding: 15px 30px;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  background-color: #3182ce;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2b6cb0;
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 12px 25px;
  }
`;

const ConclusionSection = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Content>
        {/* <h1>Conclusion</h1> */}
        <p>
          Digital Premium Tech Solutions is poised to become a leader in the technology space by delivering
          innovative solutions and empowering individuals and businesses to thrive in a digital-first world. 
          {/* Your
          investment will enable us to accelerate our growth, drive innovation, and create lasting value for our
          clients and stakeholders. */}
        </p>
        <p>
          We invite you to join us on this transformative journey and be part of shaping the future of
          technology-driven success.
        </p>
        <Button onClick={()=>navigate('/contactus')}>Join Us</Button>
      </Content>
    </Wrapper>
  );
};

export default ConclusionSection;
