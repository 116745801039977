
// import React, { useContext, useState, useRef, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
// import "../CSS2/Header.css";
// import Logo from '../Images/logo.jpeg';
// import { Context } from './Contex';
// import { useLocation } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

// const Header = () => {
//   const { active, setActive } = useContext(Context);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [menuOpen, setMenuOpen] = useState(false);
//   const menuRef = useRef(null);

//   const handleNavigate = () => {
//     navigate("/specialofferonlypage");
//   };

//   const handleNavigate2 = () => {
//     navigate("/trainingonlypage");
//   };

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   // Close menu when clicking outside
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         setMenuOpen(false);
//       }
//     };

//     // Attach the event listener to detect clicks outside
//     document.addEventListener("mousedown", handleClickOutside);

//     // Cleanup the event listener on unmount
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [menuRef]);

//   return (
//     <header className='HeaderWrap'>
//       <div className='Header'>
//         <div className='HeaderLeft'>
//           <img src={Logo} alt="logo" onClick={()=>navigate("/")} style={{cursor:'pointer'}}/>
//         </div>

//         {/* Hamburger menu for mobile */}
//         <div className={`NavToggle ${menuOpen ? 'Open' : ''}`} onClick={toggleMenu}>
//           <span className="Bar"></span>
//           <span className="Bar"></span>
//           <span className="Bar"></span>
//         </div>

//         {/* Navigation links */}
//         <nav ref={menuRef} className={`HeaderRight ${menuOpen ? 'Show' : ''}`}>
//           <NavLink to="/" className={location.pathname === "/" ? "HeaderRightActive" : ""} onClick={() => setActive("Home")}>Home</NavLink>
//           <NavLink to="/aboutus" className={location.pathname === "/aboutus" ? "HeaderRightActive" : ""} onClick={() => setActive("About")}>About us</NavLink>
//           <NavLink to="/contactus" className={location.pathname === "/contactus" ? "HeaderRightActive" : ""} onClick={() => setActive("Contact")}>Contact us</NavLink>
//         </nav>
//       </div>
//     </header>
//   );
// };

// export default Header;






import React, { useContext, useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import "../CSS2/Header.css";
import Logo from '../Images/logo.jpeg';
import { Context } from './Contex';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { active, setActive } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <header className="HeaderWrap">
      <div className="Header">
        <div className="HeaderLeft" 
        onClick={() => navigate("/")}
        style={{ cursor: 'pointer' }}
        >
          <img
            src={Logo}
            alt="logo"
            
          />
          <h1>dpts</h1>
        </div>

        {/* Hamburger menu for mobile */}
        <div
          className={`NavToggle ${menuOpen ? 'Open' : ''}`}
          onClick={toggleMenu}
        >
          <span className="Bar"></span>
          <span className="Bar"></span>
          <span className="Bar"></span>
        </div>

        {/* Navigation links */}
        <nav
          ref={menuRef}
          className={`HeaderRight ${menuOpen ? 'Show' : ''}`}
        >
          <NavLink
            to="/"
            className={location.pathname === '/' ? 'HeaderRightActive' : ''}
            onClick={() => setActive("Home")}
          >
            Home
          </NavLink>
          <NavLink
            to="/aboutus"
            className={location.pathname === '/aboutus' ? 'HeaderRightActive' : ''}
            onClick={() => setActive("About")}
          >
            About us
          </NavLink>
          <NavLink
            to="/contactus"
            className={location.pathname === '/contactus' ? 'HeaderRightActive' : ''}
            onClick={() => setActive("Contact")}
          >
            Contact us
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

export default Header;

