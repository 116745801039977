

// import React from 'react';
// // Import CSS
// import "../CSS2/Training2.css";
// // Import Images
// import TrainingImage3 from "../Images/Training pic 3.jpeg";
// import TrainingImage4 from "../Images/Training pic 4.jpeg";
// import TrainingPic3 from "../Images/Training pic 3.png"
// import TrainingPic4 from "../Images/Training pic 4.png"
// import TrainingPic5 from "../Images/Training pic 5.png"
// // Import React Router
// import { useNavigate } from 'react-router-dom';

// const Training = () => {
//     const navigate = useNavigate();
    
//     const handleNavigate = () => {
//         navigate("/trainingregistrationpage");
//     };

//     const handleNavigate2 = () => {
//         navigate("/trainingregistrationpage");
//     };

//     return (
//         <div className='TrainingWrap'>
//             <div className='Training'>
                
//                 <div className='TrainingMotivation'>
//                     <p>Become a Web Developer today!</p>
//                     <p>Master UI/UX Design and elevate your career!</p>
//                     <p>Become a Graphics designer </p>
//                     <p>Experience top-notch teaching and unbeatable pricing.</p>
//                 </div>
//                 <div className='OurTrainingHeadingWrap'>
//                     <h1>OUR TRAINING PROGRAMS AND PACKAGES</h1>
//                 </div>
//                 <div className='OurTrainings'>
//                     <div className='OurTrainingCard'>
//                         <div className='OurTrainingCardImageWrap'>
//                             <img src={TrainingPic3} alt="Front-End Development" />
//                         </div>
//                         <div className='OurTrainingCardTextWrap'>
//                             <h2>WEB DEVELOPMENT</h2>
//                             <ul>
//                                 <li>HTML, CSS</li>
//                                 <li>JavaScript, React JS</li>
//                                 <li>GitHub, Hosting</li>
//                                 <li>API Consumption</li>
//                                 <li>Live Web Applications</li>
//                                 <li>Certificate of Participation</li>
//                             </ul>
//                             <p>This course aims to make you an independent front-end web developer in just 4 months.</p>
//                             <button onClick={handleNavigate}>Register Now</button>
//                         </div>
//                     </div>
//                     <div className='OurTrainingCard'>
//                         <div className='OurTrainingCardImageWrap'>
//                             <img src={TrainingPic4} alt="UI/UX Design" />
//                         </div>
//                         <div className='OurTrainingCardTextWrap'>
//                             <h2>PRODUCT DESIGN</h2>
//                             <ul>
//                                 <li>UX Design</li>
//                                 <li>UI Design</li>
//                                 <li>Figma, FigJam</li>
//                                 <li>HTML , CSS</li>
//                                 <li>Lifetime Mentorship</li>
//                             </ul>
//                             <p>This course prepares you to become a Product Designer in just 3 months.</p>
//                             <button onClick={handleNavigate2}>Register Now</button>
//                         </div>
//                     </div>
//                     <div className='OurTrainingCard'>
//                         <div className='OurTrainingCardImageWrap'>
//                             <img src={TrainingPic5} alt="UI/UX Design" />
//                         </div>
//                         <div className='OurTrainingCardTextWrap'>
//                             <h2>GRAPHICS DESIGN</h2>
//                             <ul>
//                                 <li>Design Thinking</li>
//                                 <li>Scope and Concept</li>
//                                 <li>Design tools</li>
//                                 <li></li>
//                                 {/* <li>Lifetime Mentorship</li> */}
//                             </ul>
//                             <p>This course prepares you to become a Graphics Designer in just 3 months.</p>
//                             <button onClick={handleNavigate2}>Register Now</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Training;





// import React, { useEffect, useRef } from 'react';
// import "../CSS2/Training2.css";
// import TrainingPic3 from "../Images/Training pic 3.png";
// import TrainingPic4 from "../Images/Training pic 4.png";
// import TrainingPic5 from "../Images/Training pic 5.png";
// import { useNavigate } from 'react-router-dom';

// const Training = () => {
//     const navigate = useNavigate();
//     const cardsRef = useRef([]);

//     const handleNavigate = () => {
//         navigate("/trainingregistrationpage");
//     };

//     const handleNavigate2 = () => {
//         navigate("/trainingregistrationpage");
//     };

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             (entries) => {
//                 entries.forEach((entry) => {
//                     if (entry.isIntersecting) {
//                         entry.target.classList.add("visible");
//                     } else {
//                         entry.target.classList.remove("visible"); // Optional: Removes the animation class when out of view
//                     }
//                 });
//             },
//             { threshold: 0.1 } // Adjust threshold as needed
//         );

//         const elements = cardsRef.current;
//         elements.forEach((el) => observer.observe(el));

//         // Cleanup
//         return () => observer.disconnect();
//     }, []);

//     return (
//         <div className='TrainingWrap'>
//             <div className='Training'>
//                 {/* <div className='TrainingMotivation'>
//                     <p>Become a Web Developer today!</p>
//                     <p>Master UI/UX Design and elevate your career!</p>
//                     <p>Become a Graphics designer!</p>
//                     <p>Experience top-notch teaching and unbeatable pricing.</p>
//                 </div> */}
//                 <div className='OurTrainingHeadingWrap'>
//                     <h1>OUR TRAINING PROGRAMS</h1>
//                 </div>
//                 <div className='OurTrainings'>
//                     {[ // Array-based for cleaner code
//                         {
//                             image: TrainingPic3,
//                             title: "WEB DEVELOPMENT",
//                             details: [
//                                 "HTML, CSS",
//                                 "JavaScript, React JS",
//                                 "GitHub, Hosting",
//                                 "API Consumption",
//                                 "Live Web Applications",
//                                 "Certificate of Participation"
//                             ],
//                             duration: "This course aims to make you an independent front-end web developer in just 4 months.",
//                             handler: handleNavigate
//                         },
//                         {
//                             image: TrainingPic4,
//                             title: "PRODUCT DESIGN",
//                             details: [
//                                 "UX Design",
//                                 "UI Design",
//                                 "Figma, FigJam",
//                                 "HTML, CSS",
//                                 "Lifetime Mentorship"
//                             ],
//                             duration: "This course prepares you to become a Product Designer in just 3 months.",
//                             handler: handleNavigate2
//                         },
//                         {
//                             image: TrainingPic5,
//                             title: "GRAPHICS DESIGN",
//                             details: ["Design Thinking", "Scope and Concept", "Design tools"],
//                             duration: "This course prepares you to become a Graphics Designer in just 3 months.",
//                             handler: handleNavigate2
//                         },
//                         {
//                             image: TrainingPic5,
//                             title: "GRAPHICS",
//                             details: ["Design Thinking", "Scope and Concept", "Design tools"],
//                             duration: "This course prepares you to become a Graphics Designer in just 3 months.",
//                             handler: handleNavigate2
//                         }
//                     ].map((training, index) => (
//                         <div
//                             key={index}
//                             className='OurTrainingCard'
//                             ref={(el) => (cardsRef.current[index] = el)}
//                         >
//                             <div className='OurTrainingCardImageWrap'>
//                                 <img src={training.image} alt={training.title} />
//                             </div>
//                             <div className='OurTrainingCardTextWrap'>
//                                 <h2>{training.title}</h2>
//                                 {/* <ul>
//                                     {training.details.map((detail, i) => (
//                                         <li key={i}>{detail}</li>
//                                     ))}
//                                 </ul> */}
//                                 <p>{training.duration}</p>
//                                 <button onClick={training.handler}>Register Now</button>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Training;






import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";

// Styled components for styling
const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
//   background: linear-gradient(120deg, #e0f7fa, #fff);
background:box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-family: "Arial", sans-serif;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(0,0,255,0.5);
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #4a4a4a;
  max-width: 800px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  background: white;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const IconWrapper = styled.div`
//   background: rgba(0,0,255,0.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: white;
  font-size: 3rem;
`;

const ServiceName = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(0,0,255,0.5);
  margin: 20px 0 10px;
  text-align: center;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #6c6c6c;
  padding: 0 15px;
  text-align: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
    background:rgba(0,0,255,0.5);
    border:none;
    border-radius:5px;
    color:white;
    cursor:pointer;
    margin-bottom:20px;
    padding:5px 10px;

    &:hover{
        background:rgba(0,0,255,0.7)
    }
`

// const servicesData = [
//   { name: "Web Development", icon: "💻" },
//   { name: "UI/UX Design", icon: "🎨" },
//   { name: "Digital Marketing", icon: "📈" },
//   { name: "AI Implementation on Projects", icon: "🤖" },
//   { name: "AI and Machine Learning", icon: "🧠" },
//   { name: "Graphics Design", icon: "🖼️" },
//   { name: "Content Creation", icon: "✍️" },
//   { name: "Video Editing", icon: "🎥" },
//   { name: "Data Analysis", icon: "📊" },
//   { name: "Cloud Computing", icon: "☁️" },
//   { name: "Cybersecurity", icon: "🔒" },
// ];

const trainingCourses = [
  { name: "Web Development", icon: "💻", description: "Learn to build responsive and functional web applications and websites." },
  { name: "UI/UX Design", icon: "🎨", description: "Master the art of crafting user-friendly and visually appealing designs." },
  { name: "Digital Marketing", icon: "📈", description: "Understand strategies to boost online presence and engagement." },
  { name: "AI Implementation on Projects", icon: "🤖", description: "Explore how to integrate AI solutions into real-world projects." },
  { name: "AI and Machine Learning", icon: "🧠", description: "Dive into developing intelligent systems that analyze and learn." },
  { name: "Graphics Design", icon: "🖼️", description: "Enhance your skills in creating stunning visuals and branding materials." },
  { name: "Content Creation", icon: "✍️", description: "Learn to produce engaging and high-quality content." },
  { name: "Video Editing", icon: "🎥", description: "Transform raw footage into professional, captivating videos." },
  { name: "Data Analysis", icon: "📊", description: "Gain expertise in extracting insights and trends from data." },
  { name: "Cloud Computing", icon: "☁️", description: "Understand how to provide scalable and efficient cloud solutions." },
  { name: "Cybersecurity", icon: "🔒", description: "Learn to ensure data and systems are secure from threats." },
];


const Training = () => {

    const navigate = useNavigate();

  return (
    <ServicesContainer>
      <Heading>Our Training Programs</Heading>
      <Description>
        We are committed to enhancing digital literacy and enabling individuals
        to start or advance their careers in tech. Our training programs
        include:
      </Description>
      <ServicesWrapper>
        {trainingCourses.map((course, index) => (
          <ServiceCard key={index}>
            <IconWrapper>{course.icon}</IconWrapper>
            <ServiceName>{course.name}</ServiceName>
            <ServiceDescription>
              {course.description}
            </ServiceDescription>
            <Button onClick={()=>navigate('/contactus')}> Register</Button>
          </ServiceCard>
        ))}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Training;
