import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import industryBg from './path-to-your-background-image.jpg'; // Replace with the actual path to your background image

// Main Wrapper for the Component
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 5px;
  min-height: 100vh;
  width: 100%;
 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 0;
  }
`;

// Content Container
const ContentContainer = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: rgba(0,0,255,0.5);
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.5em;
    }

    p {
      font-size: 1em;
    }
  }
`;

// Product Section
const ProductSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  z-index: 1;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Product Card
const ProductCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 1.5em;
    color:rgba(0,0,255,0.5);
    margin-bottom: 10px;
  }

  p {
    font-size: 1em;
    color: #333;
    margin-bottom: 20px;
  }

  button {
    background-color: rgba(0,0,255,0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0,0,255,0.7);
    }
  }

  @media (max-width: 768px) {
    padding: 15px;

    h3 {
      font-size: 1.3em;
    }

    p {
      font-size: 0.9em;
    }

    button {
      font-size: 0.9em;
      padding: 8px 16px;
    }
  }
`;

const IndustryProducts = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <ContentContainer>
        <h1>Our Industry-Specific Products</h1>
        <p>
          We have developed specialized web products tailored to the needs of specific industries.
        </p>
      </ContentContainer>

      <ProductSection>
        <ProductCard>
          <h3>Academy Portal for Schools</h3>
          <p>
            Streamlines student management, fees, results, school administrative roles, teacher roles, student roles and lots more.
          </p>
          <button onClick={()=>navigate('/schoolportalfeatures')}>View more Features </button>
        </ProductCard>

        <ProductCard>
          <h3>Medical Appointment Booking System</h3>
          <p>
            Simplifies scheduling for patients and healthcare providers in hospitals and clinics.
          </p>
          <button onClick={()=>navigate('/medicalappfeatures')}>View more Features</button>
        </ProductCard>
      </ProductSection>
    </Wrapper>
  );
};

export default IndustryProducts;
