import React from "react";
import styled from "styled-components";
import { FaUserShield, FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa";
import { motion } from "framer-motion";
import schoolportalbg from '../Images/schoolportalbg.jpg'
import {useNavigate} from 'react-router-dom'

const SchoolPortalFeatures = () => {
  const navigate = useNavigate();
  const sections = [
    {
      title: "Admin Dashboard",
      icon: <FaUserShield />,
      features: [
        "Admin Registration with profile photo",
        "Admin login and logout",
        "Forgot and reset password",
        "Edit phone numbers",
        "Post announcements, with edit and delete",
        "View classes",
        "View all teachers info",
        "Set current term",
        "Generate all student results",
        "Promote/demote students to/from the next/previous class",
        "Reset all student score sheet for next term",
        "Students pickup ID automation and manual regeneration",
        "Search student by reg number",
        "View students by class (with suspend, unsuspend, delete, promote, demote options)",
        "Register students with details and profile photo",
        "Register admins/teachers with details and profile photo",
        "Create and update school calendar termly",
        "View feedbacks from students and teachers",
        "Create and edit school fees for each class and term",
        "Manage school fees payments",
        "Sort students by scores to get positions (e.g., 1st, 2nd, 3rd)",
        "Suspend and unsuspend teachers",
        "Change profile photo",
      ],
    },
    {
      title: "Student Dashboard",
      icon: <FaUserGraduate />,
      features: [
        "Student login",
        "Student password change",
        "Phone number change",
        "View class teacher info",
        "View announcements from school management",
        "View school fees for class and terms",
        "Pay school fees and receive automatic receipts via email",
        "View school termly calendar",
        "Post feedback to management",
        "View subjects for class",
        "View results for selected class and term (if fees are paid)",
        "Change profile photo",
        "Logout",
      ],
    },
    {
      title: "Teacher Dashboard",
      icon: <FaChalkboardTeacher />,
      features: [
        "Login",
        "View personal info",
        "View announcements from school management",
        "View all students in their class",
        "View school termly calendar",
        "Post feedback to school management",
        "Post assignments for students",
        "Update students' score sheets for their class",
        "Change profile photo",
        "Logout",
      ],
    },
  ];

  return (
    <PageWrapper>
      <IntroSection>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <IntroText>
            <h1>Welcome to Our School Portal</h1>
            <p>
              Manage your school operations seamlessly with our all-in-one
              portal designed for Administrators, Teachers, and Students.
              Explore the dashboards below to learn more about the features.
            </p>
          </IntroText>
        </motion.div>
      </IntroSection>
      <FeaturesSection>
        {sections.map((section, index) => (
          <FeatureCard
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <CardHeader>
              <Icon>{section.icon}</Icon>
              <Title>{section.title}</Title>
            </CardHeader>
            <FeaturesList>
              {section.features.map((feature, idx) => (
                <FeatureItem key={idx}>• {feature}</FeatureItem>
              ))}
            </FeaturesList>
          </FeatureCard>
        ))}
      </FeaturesSection>
      <ButtonWrap>
      <Button onClick={()=>navigate('/contactus')}>Request Now</Button>
      </ButtonWrap>
      
    </PageWrapper>
  );
};

export default SchoolPortalFeatures;

// Styled Components
const PageWrapper = styled.div`
  width: 100%;
  // background: linear-gradient(135deg, #f0fff4, #c6f6d5);
  padding: 5px;
  color: #333;
  padding-top:50px;
  width:100%;
  background-image:url(${schoolportalbg});
   background-position: center;
      position: relative;
      background-size: cover;
`;

const IntroSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  text-align: center;
  background-color:rgba(255,255,255,0.7);
`;

const IntroText = styled.div`
  max-width: 800px;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(0,0,255,0.5);
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
    // color: #2d3748;
  }

  @media(max-width:428px){
    h1{
      font-size:2rem;
    }
  }
`;

const FeaturesSection = styled.section`
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display:flex;
  justify-content:center;
  // align-items:center;
  gap: 10px;
  padding: 20px 5px;
  flex-wrap:wrap;
`;

const FeatureCard = styled(motion.div)`
  background: rgba(255,255,255,0.7);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  width:45%;
  // justify-content: center;

  @media(max-width:768px){
    width:100%;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: rgba(0,0,255,0.5);
  margin-right: 10px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  color: #4a5568;
  margin-bottom: 10px;
`;

const ButtonWrap = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  padding:50px;
`


const Button = styled.button`
padding:20px;
// background-color:rgba(0,0,255,0.5);
background-color:white;
color:rgba(0,0,255,0.5);
font-weight:bold;
border:none;
border-radius:10px;
cursor:pointer;


&:hover{
  // background-color:rgba(0,0,255,0.7);
  text-decoration:underline;
}
`