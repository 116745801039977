import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaCode, FaChalkboardTeacher, FaGraduationCap, FaIndustry, FaClipboardList } from "react-icons/fa";
import classified from '../Images/classified.jpg'
import operationsbg from '../Images/1919.jpg'


const Operations = () => {
  const operations = [
    {
      name: "Tech Services",
      icon: <FaCode />,
      description: "We Provide digital technological and artificial intelligence (AI) solutions tailored to your needs.",
    },
    {
      name: "Tech Trainings",
      icon: <FaChalkboardTeacher />,
      description: "We Empowering individuals with hands-on digital tech training programs with certifications and internships.",
    },
    {
      name: "Online Academy",
      icon: <FaGraduationCap />,
      description: "Learn at your pace with our robust online learning platform with AI powered tests and certifications.",
    },
    {
      name: "Industry-Specific Web Products",
      icon: <FaIndustry />,
      description: "We Deliver specialized web and AI solutions to meet industry demands.",
    },
    {
      name: "Online Classified",
      icon: <FaClipboardList />,
      description: "A trusted platform for classified ads and posts with AI verification.",
    },
  ];

  return (
    <Section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <Heading>Our Arms of Operations</Heading>
        <Grid>
          {operations.map((operation, index) => (
            <Card key={index}>
              <Icon>{operation.icon}</Icon>
              <CardTitle>{operation.name}</CardTitle>
              <CardDescription>{operation.description}</CardDescription>
            </Card>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Operations;

// Styled Components
const Section = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
    background:rgba(0,0,255,0.1);
  padding: 40px 20px;
  background-image:url(${classified});
   background-position: center;
      position: relative;
      background-size: cover;
      margin-bottom:10px;
  
  
        &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(255, 255, 255, 0.5);
      z-index: 0;
`;

const Container = styled.div`
  max-width: 1200px;
  text-align: center;
  position:relative;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 40px;
//   color: rgba(0,0,255,0.5);
   color: white;

    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
    

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Grid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
//   gap: 20px;
`;

const Card = styled.div`
  // background: rgba(255,255,255,0.5);
  background:white;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin-bottom:10px;

  &:hover {
    transform: translateY(-10px);
  }
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: rgba(0,0,255,0.5);
  margin-bottom: 20px;
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  color:rgba(0,0,255,0.5);
`;

const CardDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
`;

