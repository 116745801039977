
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import logo from '../Images/logo.jpeg';
// import backgroundVideo from '../Images/media11.mp4'; // Add your video path here
import backgroundVideo from '../Images/intromedia8.mp4'


const Intro = () => {
  return (
    <Section
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <VideoBackground autoPlay muted loop>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>
      <Container>
        <Heading>Digital Premium Tech Solutions</Heading>
        <Img src={logo} alt="logo" />
        <Description>
          Digital Premium Tech Solutions is a cutting-edge technology establishment
          dedicated to empowering businesses, individuals, and organizations with innovative
          digital solutions and services. Our mission is to revolutionize industries by
          leveraging technology and artificial intelligence (AI) to enhance productivity,
          efficiency, and accessibility. With a diverse range of services, training
          programs, and proprietary platforms, we aim to create a future where technology is
          seamlessly integrated into daily operations and personal growth.
        </Description>
      </Container>
    </Section>
  );
};

export default Intro;

// Styled Components
const Section = styled(motion.div)`
  position: relative;
  width: 100%;
  padding: 80px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  min-height: 70vh;
  overflow: hidden;
  margin-top:10px;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Container = styled.div`

  max-width: 800px;
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  // backdrop-filter: blur(10px);
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  color:white;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;

  color:white;
`;

const Img = styled.img`
  width: 100px;
  border-radius:50%;
`;

