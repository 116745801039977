import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const AIModels = () => {
  return (
    <Section
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <Heading>Our Artificial Intelligence (AI) Models</Heading>
        <Heading2>
          At <span>Digital Premium Tech Solutions</span>, we are leveraging Artificial Intelligence (AI) in the following areas:
        </Heading2>
        <Content>
          <Category>
            <CategoryTitle>Our Service</CategoryTitle>
            <CategoryDescription>
              Rendering of AI integration on projects as part of our service.
            </CategoryDescription>
          </Category>

          <Category>
            <CategoryTitle>Our Training</CategoryTitle>
            <CategoryDescription>
              <ul>
                <li>AI implementation on projects</li>
                <li>AI and machine learning</li>
              </ul>
            </CategoryDescription>
          </Category>

          <Category>
            <CategoryTitle>Our Online Academy</CategoryTitle>
            <CategoryDescription>
              <ul>
                <li>Courses on AI integration on projects</li>
                <li>Courses on AI and machine learning</li>
                <li>
                  AI-generated examinations for users, leading to certification after completing our online courses.
                </li>
                <li>
                  AI screening of courses produced and uploaded by our tutors and external tutors before being published.
                </li>
              </ul>
            </CategoryDescription>
          </Category>

          <Category>
            <CategoryTitle>Our Online Classified Platform</CategoryTitle>
            <CategoryDescription>
              AI screening of posts and ads before being published on the platform.
            </CategoryDescription>
          </Category>
        </Content>
      </Container>
    </Section>
  );
};

export default AIModels;

// Styled Components
const Section = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
//   background: linear-gradient(135deg, #d4f1d8, #9fe6a0);
background:rgba(0,0,255,0.1);
  color: #333;
  padding: 40px 20px;
`;

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
//   padding: 20px;
//   background: rgba(255, 255, 255, 0.9);
//   border-radius: 15px;
//   box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  // color: rgba(0,0,255,0.5);
  color:white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
    

  span {
    color: rgba(0,0,255,0.5);
    font-weight: 800;
  }
`;

const Heading2 = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
//   color: rgba(0,0,255,0.5);
color:white;
text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;

  span {
    // color: rgba(0,0,255,0.5);
    font-weight: 800;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Category = styled.div`
  flex: 1;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }
`;

const CategoryTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: rgba(0,0,255,0.5);
  text-align:center;
`;

const CategoryDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  

  ul {
    padding-left: 20px;
    list-style-type: disc;

    li {
      margin-bottom: 10px;
    }
  }
`;
