import React from "react";
import styled from "styled-components";
import { FaBook, FaCertificate, FaMoneyBillWave, FaChalkboardTeacher } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// Styled Components
const AcademyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
//   background: linear-gradient(120deg, #e0f7fa, #f9f9f9);
  font-family: "Arial", sans-serif;
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: rgba(0,0,255,0.5);
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SubHeading = styled.p`
  font-size: 1.3rem;
  color: #4a4a4a;
  max-width: 900px;
  text-align: center;
  margin-bottom: 50px;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
`;

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  padding: 30px 20px;
  background: white;
  border-radius: 15px;
 box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const IconWrapper = styled.div`
  background: rgba(0,0,255,0.5);
  color: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(0,0,255,0.5);
  margin-bottom: 10px;
  text-align: center;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #6c6c6c;
  text-align: center;
  line-height: 1.6;
`;



const Button = styled.button`
    background:rgba(0,0,255,0.5);
    border:none;
    border-radius:5px;
    color:white;
    cursor:pointer;
    margin-top:30px;
    margin-bottom:20px;
    padding:10px 50px;

    &:hover{
        background:rgba(0,0,255,0.7)
    }
`


const featuresData = [
  {
    title: "Self-Paced Learning",
    description:
      "Courses in digital tech, coding, artificial intelligence (AI), programming, accounting, media, and lots more to empower learners at their own pace.",
    icon: <FaBook />,
  },
  {
    title: "Certification",
    description:
      "AI-generated test examinations ensure your skills are validated with certification.",
    icon: <FaCertificate />,
  },
  {
    title: "Affiliate Referral Program",
    description:
      "Earn a 20% commission from courses sold through your referral links. Build a secondary income source effortlessly.",
    icon: <FaMoneyBillWave />,
  },
  {
    title: "Empowering Educators",
    description:
      "Tutors and professionals can upload and sell courses, earning revenue while retaining 80% of each sale.",
    icon: <FaChalkboardTeacher />,
  },
];

const OnlineAcademy = () => {
const navigate = useNavigate();

  return (
    <AcademyContainer>
      <Heading>Our Online Academy Platform</Heading>
      <SubHeading>
        Our online academy empowers individuals to learn at their own pace
        through a robust e-learning platform and AI-generated examinations for their certifications. Here's what we offer:
      </SubHeading>
      <FeaturesWrapper>
        {featuresData.map((feature, index) => (
          <FeatureCard key={index}>
            <IconWrapper>{feature.icon}</IconWrapper>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureCard>
        ))}
      </FeaturesWrapper>
      <Button onClick={() => window.open('https://google.com', "_blank")}>Explore</Button>

    </AcademyContainer>
  );
};

export default OnlineAcademy;
